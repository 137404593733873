import React, { useState, useRef, useMemo } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import {
  Autocomplete,
  Text,
  Stack,
  Card,
  Button,
  Flex,
  Box
} from '@contentful/f36-components';
import { debounce } from 'lodash';
import { client, gql } from '../services/graphql';
import { useDynamicHeight } from '../Field.hooks';

interface VariantSkuFieldFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

export interface Item {
  [index: string]: unknown;
  sku: string;

  InventoryItems: Array<{
    available: number;
    Store: { region: string };
  }>;
  Sku: {
    [index: string]: unknown;
    id: string;
    cnl: string;
    active: boolean;
    sku: string;
    Colorway: {
      [index: string]: unknown;
      id: string;
      cnl: string;
      ColorSpecification: {
        [index: string]: unknown;
        id: string;
        cnl: string;
        active: boolean;
      };
    };
    ProductSize: {
      [index: string]: unknown;
      id: string;
      cnl: string;
    };
    Season: {
      [index: string]: unknown;
      id: string;
      cnl: string;
      active: boolean;
    };
    Style: {
      [index: string]: unknown;
      id: string;
      cnl: string;
      active: boolean;
      name: string;
      bee_parent_item: string;
      composition: string;
      care_instructions: string;
      StyleTag: {
        [index: string]: unknown;
        id: string;
        cnl: string;
        category: string;
        class: string;
        collection: string;
      };
    };
  };
}

export enum Status {
  Idle = -1,
  Loading = 0,
  Success = 1,
  Failure = 2
}

const VariantSkuField = ({ sdk }: VariantSkuFieldFieldProps) => {
  const baseRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLUListElement>(null);
  const [displayValue, setDisplayValue] = useState(sdk.field.getValue());
  const [loadingState, setLoadingState] = useState<Status>(Status.Idle);
  const [items, setItems] = useState<Array<Item>>([]);
  const debouncedQuery = useMemo(
    () =>
      debounce((value) => {
        client(sdk.ids.environment)
          .request(
            gql`
              query itemSearchRaw($query: String!) {
                itemSearchRaw(query: $query)
              }
            `,
            { query: value }
          )
          .then((res) => {
            const foundItems = res?.itemSearchRaw || [];
            setItems(foundItems);
            setLoadingState(Status.Success);
          })
          .catch((err) => {
            console.error(err);
            setLoadingState(Status.Failure);
          });
      }, 300),
    []
  );
  const showButton = inputRef?.current?.value && items.length < 1;

  useDynamicHeight(sdk, { baseRef, fieldRef });

  const handleInputValueChange = (value: string | Item) => {
    if (value !== '') {
      setLoadingState(Status.Loading);
      debouncedQuery(value);
    }
  };

  const handleSelectItem = (item: Item) => {
    sdk.field.setValue(item.sku);
    setDisplayValue(item.sku);
  };

  return (
    <div ref={baseRef}>
      <Card>
        <Stack flexDirection="column" alignItems="start" spacing="spacingS">
          <Text>Current Value: {displayValue}</Text>
          <Autocomplete
            listMaxHeight={300}
            listRef={fieldRef}
            inputRef={inputRef}
            onInputValueChange={handleInputValueChange}
            onSelectItem={handleSelectItem}
            isLoading={loadingState === Status.Loading}
            clearAfterSelect={true}
            listWidth="full"
            noMatchesMessage="No items found, try being more specific or force submit the SKU."
            items={items}
            itemToString={(item: Item) => item?.sku}
            renderItem={(item: Item) => (
              <Flex flexDirection="column">
                <Box>
                  <Text fontWeight="fontWeightMedium">
                    SKU: {item?.sku} - {item?.Sku?.Style?.name}{' '}
                  </Text>
                  <Text>
                    (
                    {item?.InventoryItems?.find((x) => x.Store.region === 'US')
                      ?.available || '0'}
                    )
                  </Text>
                </Box>
                <Stack
                  flexDirection="column"
                  flexWrap="wrap"
                  spacing="none"
                  alignItems="left"
                >
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Marketing Name: {item?.Sku?.Style?.site_name}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Style: {item?.Sku?.Style?.StyleTag?.node_name} -{' '}
                      {item?.Sku?.Style?.style_number}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Color:{' '}
                      {item?.Sku?.Colorway?.site_color || 'Missing from PLM'}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Replenishment: {item?.Sku?.Colorway?.core_fashion || 'Missing from PLM'}
                    </Text>
                  </Box>
                </Stack>
              </Flex>
            )}
          />
          <Text fontColor="gray500" fontSize="fontSizeS">
            Search by SKU, style number, and more! If your search is not showing
            the results you expect, but you are sure of the SKU that you are
            inputting, you can use the submit button in the bottom right corner
            of this SKU widget.
          </Text>
        </Stack>
      </Card>
      {showButton && (
        <div style={{ width: '100%', textAlign: 'right', paddingTop: '12px' }}>
          <Button
            onClick={() => {
              if (inputRef?.current?.value) {
                handleSelectItem({ sku: inputRef.current.value } as Item);
              }
            }}
          >
            Submit SKU
          </Button>
        </div>
      )}
    </div>
  );
};

export default VariantSkuField;
